import { gsap, startScroll, stopScroll } from "../utilities/scroll-config";
import SplitTextJS from 'split-text-js';
import teamSlider from "./slider-team";
import cookiesPopup from "./cookies";

let loaded = false;

const onloadAnimations = () => {

  const preloaderWrapper = document.getElementById('preLoader');
  const welcomeScreen = document.querySelector('.welcome');

  const header = document.querySelector('header');
  const aside = document.querySelector('aside');

  let animatePageTitle = gsap.timeline({ paused: true });
  let animatePageElements = gsap.timeline({ paused: true });
  let animateLayoutElements = gsap.timeline({ paused: true });

  if (preloaderWrapper) {
    preloader();
  } else {
    afterLoad();
  }

  welcomeAnimation();

  function afterLoad() {
    animatePageTitle.play();
    teamSlider();
    cookiesPopup();
  }

  function preloader() {

    stopScroll();

    const logo = preloaderWrapper.querySelector('.logo');
    const images = preloaderWrapper.querySelectorAll('.images > div');

    const endPreloader = gsap.timeline({ paused: true })

      .to(images, { duration: 1, autoAlpha: 1, x: 0, scale: "random(0.7, 1.1)", ease: "power4.out", stagger: 0.05 }, "load")
      .to(images, { duration: 1, autoAlpha: 0, scale: 0.5 }, "load+=0.75")
      .call(() => {
        startScroll();
        afterLoad();
      })
      .to(preloaderWrapper, { duration: 0.5, yPercent: -100, ease: "power4.out" });

    const startPreloader = gsap.timeline({
      paused: true,
      onComplete: () => endPreloader.play(),
      repeat: 1,
      repeatDelay: 1,
      yoyo: true
    })
      .to(logo, { duration: 0.5, autoAlpha: 1, ease: "none" })

    function preloadLinks() {
      const links = [...document.querySelectorAll('a')];
      const localLinks = links.filter(l => l.href.includes(location.origin));
      if (!localLinks) return;

      localLinks.forEach(a => {
        a.addEventListener('click', e => {
          e.preventDefault();

          gsap.timeline({
            onComplete() {
              window.location = a.href;
            }
          })
            .to(preloaderWrapper, { duration: 1, yPercent: 0, ease: "power4.out" });
        });
      });
    }

    if (!sessionStorage.isVisited) {
      sessionStorage.isVisited = 'true';
      startPreloader.play();
    } else {
      endPreloader.play();
    }

    preloadLinks();

  }

  function welcomeAnimation() {

    if (!welcomeScreen) return;

    const title = welcomeScreen.querySelector('h1');

    const showPageTitle = () => {
      let splitTitle;

      if (title) {
        splitTitle = new SplitTextJS(title);
        animatePageTitle
          .from(splitTitle.chars, { duration: 0.5, yPercent: 100, autoAlpha: 0, stagger: 0.05 })
          .call(() => {
            animatePageElements.play();
            animateLayoutElements.play();
          }, null, "-=0.5")
      } else {
        animatePageElements.play();
        animateLayoutElements.play();
      }
    }

    const showPageElements = () => {

      let fadeUps = [...welcomeScreen.querySelectorAll('[data-fadeup]')];
      let fadeIns = [...welcomeScreen.querySelectorAll('[data-fadein]')];

      if (fadeUps.length) {

        // Sort by data atribbute
        fadeUps.sort(function (a, b) {
          return a.dataset.fadein - b.dataset.fadein;
        })

        animatePageElements
          .from(fadeUps, { duration: 2, yPercent: 50, autoAlpha: 0, ease: "expo.out", stagger: 0.25 }, "animatehome");
      }

      if (fadeIns.length) {
        animatePageElements
          .from(fadeIns, { duration: 1, autoAlpha: 0, ease: "none", stagger: 0.25 }, "animatehome");
      }
    }

    const showLayoutElements = () => {
      animateLayoutElements
        .from(header, { duration: 1, yPercent: -50, autoAlpha: 0, ease: "none", }, "layout")
        .from(aside, { duration: 2, autoAlpha: 0, ease: "none", }, "layout")
    }

    showPageTitle();
    showPageElements();
    showLayoutElements();

  }
}

export default onloadAnimations;