import { gsap, ScrollTrigger, locoScroll } from "../utilities/scroll-config";

const onscrollAnimations = () => {

  const sections = [...document.querySelectorAll('section')];
  const contacts = sections.filter(s => s.classList.contains('contacts'))[0];
  const header = document.querySelector('header');
  const headerLogo = header.querySelector('.header-logo');
  const headerNav = header.querySelector('.header-menu');
  const mobileNav = header.querySelector('.mobile-menu');
  const headerOffset = header.clientHeight / 2;
  const footer = document.querySelector('footer');
  const socials = [...document.querySelectorAll('aside a')];
  const scrollButton = document.getElementById('scrollDown');

  const toggleActions = "play none play reverse";

  sections.length && sections.forEach(s => !s.classList.contains('welcome') && sectionScrollAnimations(s));
  toggleOnScroll();

  function sectionScrollAnimations(section) {
    const fadeUps = [...section.querySelectorAll('[data-fadeup]')];
    const fadeIns = [...section.querySelectorAll('[data-fadein]')];
    const svgFadeIns = [...section.querySelectorAll('[data-svg-fadein]')];
    const parallaxes = [...section.querySelectorAll('[data-parallax-y]')];

    fadeUps.length && fadeUps.forEach(el => fadeUpOnScroll(el));
    fadeIns.length && fadeIns.forEach(el => fadeInOnScroll(el));
    svgFadeIns.length && svgFadeIns.forEach(el => fadeInSvgOnScroll(el));
    parallaxes.length && parallaxes.forEach(el => parallaxOnScroll(el));
  }

  function fadeUpOnScroll(el) {

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        toggleActions: toggleActions
      }
    });

    tl
      .from(el, { duration: 1.5, y: 100, autoAlpha: 0 }, "fadeup")
  }

  function fadeInOnScroll(el) {

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        toggleActions: toggleActions
      }
    });

    tl
      .from(el, { duration: 2, autoAlpha: 0, ease: "none" }, "fadein")
  }

  function fadeInSvgOnScroll(wrapper) {

    const svg = wrapper.querySelector('svg');
    const pathes = svg.querySelectorAll('path');

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapper,
        toggleActions: toggleActions
      }
    });

    tl
      .from([pathes], { duration: 0.5, autoAlpha: 0, stagger: { each: 0.05, from: "end" } }, "fadeinsvg")
  }

  function parallaxOnScroll(el) {

    let direction = el.dataset.parallaxY === 'invert' ? '-' : '';

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        scrub: 2
      }
    });

    tl.from(el, { duration: 1, yPercent: `${direction}30` })
  }

  function toggleOnScroll() {

    const getInverted = (s) => {

      let invert = s.dataset.invert;
      return (invert) ? invert.split(',') : [];
    }

    const invertLogoSections = sections.filter(s => getInverted(s).includes('logo') && s);
    const invertNavSections = sections.filter(s => getInverted(s).includes('nav') && s);
    const invertAsideSections = sections.filter(s => getInverted(s).includes('aside') && s);

    invertLogoSections.push(footer);
    invertNavSections.push(footer);

    const initToggleLogo = (trigger) => {

      ScrollTrigger.create({
        trigger: trigger,
        start: `top ${headerOffset}px`,
        end: `bottom ${headerOffset}px`,
        toggleClass: { targets: headerLogo, className: 'inverted' }
      });
    }

    const initToggleNav = (trigger) => {

      ScrollTrigger.create({
        trigger: trigger,
        start: `top ${headerOffset}px`,
        end: `bottom ${headerOffset}px`,
        toggleClass: { targets: [headerNav, mobileNav], className: 'inverted' }
      });
    }

    const initToggleAside = (trigger) => {

      const toggleSocialsColor = (e, icon) => icon.classList.toggle('inverted', e.isActive);

      socials.forEach(icon => {

        const offset = icon.getBoundingClientRect();

        ScrollTrigger.create({
          trigger: trigger,
          start: `top ${offset.bottom}px`,
          end: `bottom ${offset.top}px`,
          onToggle: e => toggleSocialsColor(e, icon),
          onUpdate: e => toggleSocialsColor(e, icon)
        });
      })
    }

    const initHideSocials = (trigger) => {
      if (!trigger) return;

      const tl = gsap.timeline({ paused: true }).to(socials, { duration: 0.6, autoAlpha: 0, ease: "none" });
      const toggleSocials = (e) => e.isActive ? tl.play() : tl.reverse();

      ScrollTrigger.create({
        trigger: trigger,
        onToggle: e => toggleSocials(e),
        onUpdate: e => toggleSocials(e)
      });
    }

    const initToggleScrollDown = (trigger) => {

      !trigger && gsap.to(scrollButton, { autoAlpha: 0 });

      const toggleScrollDown = gsap.timeline({ paused: true }).to(scrollButton, { duration: 0.3, autoAlpha: 0 });

      ScrollTrigger.create({
        trigger: trigger,
        onEnter: () => toggleScrollDown.play(),
        onLeaveBack: () => toggleScrollDown.reverse()
      });

      scrollButton.addEventListener('click', () => {
        locoScroll.scrollTo(trigger, { duration: 750 });
      });
    }

    // Toggle logo in header
    invertLogoSections.length && invertLogoSections.forEach(s => initToggleLogo(s));

    // Toggle menu in header
    invertNavSections.length && invertNavSections.forEach(s => initToggleNav(s));

    // Toggle social icons in aside
    invertAsideSections.length && invertAsideSections.forEach(s => initToggleAside(s));

    // Scrolldown toggle
    initToggleScrollDown(sections[1] ?? null);

    // Socials toggle when reach contacts / footer section
    initHideSocials(contacts);
    initHideSocials(footer);
  }

}

export default onscrollAnimations;