import "./utilities/index";
// import partnerSlider from "./modules/slider-partners";
import roadmapSlider from "./modules/slider-roadmap";
// import videoPlayer from "./modules/video-player";
import Form from "./modules/form";
// import blogSlider from "./modules/slider-blog";
import accordeon from "./modules/accordeon";
import accordeonList from "./modules/accordeon-list";
import onloadAnimations from "./modules/animations-onload";
import onscrollAnimations from "./modules/animations-onscroll";
// import articleNavigation from "./modules/article-navigation";
import copyToClipboard from "./modules/copy-to-clipboard";
import toggleMenu from "./modules/toggle-menu";
import raritySlider from "./modules/slider-rarity";
import accordeonSlider from "./modules/slider-accordeon";
import cardsSlider from "./modules/slider-customers-cards";
import nftSlider from "./modules/slider-nfts";

document.addEventListener("DOMContentLoaded", () => {
  nftSlider();
  // partnerSlider();
  // blogSlider();
  // videoPlayer();
  Form();
  accordeon();
  accordeonList();
  roadmapSlider();
  raritySlider();
  accordeonSlider();
  // articleNavigation();
  copyToClipboard();
  cardsSlider();

  onloadAnimations();
  onscrollAnimations();

  toggleMenu();
});
